var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-page',{attrs:{"title":_vm.$t('labels.deployments')}},[_c('f-card',[_c('f-datatable',{ref:"deploymentTable",attrs:{"actions":_vm.deploymentActions,"columns":_vm.deploymentColumns,"data-url":'/projects/repositories',"features":{
        create: _vm.isCreateDeploymentEnabled,
        update: true,
        delete: true
      },"query-params":_vm.search},on:{"action":_vm.onClickOpenDeploy,"create":_vm.onClickCreateDeployment,"delete":_vm.onClickDeleteDeployment,"update":_vm.onClickUpdateDeployment}})],1),_c('f-modal',{ref:"modalDeploymentEdit",attrs:{"size":"lg","title":_vm.$t('labels.repo')},on:{"submit":_vm.saveDeployment}},[(_vm.selectedDeployment.id)?_c('template',{slot:"header-right"},[(_vm.selectedDeployment)?_c('f-user-edit-info',{attrs:{"data":_vm.selectedDeployment}}):_vm._e()],1):_vm._e(),_c('modal-deployment-edit',{attrs:{"deployment":_vm.selectedDeployment}})],2),_c('f-modal',{ref:"modalDeployment",attrs:{"cancel-disabled":"","size":"lg","submit-disabled":"","title":_vm.selectedDeployment.name}},[(_vm.selectedDeployment.id)?_c('template',{slot:"header-right"},[(_vm.selectedDeployment)?_c('f-user-edit-info',{attrs:{"data":_vm.selectedDeployment}}):_vm._e()],1):_vm._e(),_c('template',{slot:"footer"},[_c('f-button',{attrs:{"disabled":!_vm.selectedDeployment.deploymentType && !_vm.selectedDeployment.isForce,"icon":"rocket","label":_vm.$t('labels.deploy'),"variant":"primary"},on:{"click":_vm.onClickDeploy}})],1),_c('ModalDeployment',{attrs:{"deployment":_vm.selectedDeployment}})],2),_c('f-modal',{ref:"deploymentStatus",attrs:{"cancel-disabled":"","hide-footer":"","size":"md","submit-disabled":"","title":_vm.deploymentHeader()}},[_c('div',{staticClass:"alert alert-light",style:({
        'background-color':
          _vm.deploymentResponse.status == 201 || _vm.deploymentResponse.message
            ? 'palegreen'
            : 'pink'
      }),attrs:{"aria-atomic":"true","role":"alert","show":"true","variant":"light"}},[_vm._v(" "+_vm._s(_vm.deploymentMessage())+" ")]),_c('br'),_c('div',{staticClass:"d-flex justify-content-end"},[_c('f-button',{attrs:{"label":_vm.$t('labels.ok'),"variant":"light"},on:{"click":function () {
            _vm.$refs.deploymentStatus.hide()
          }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }