<template>
  <div>
    <f-row>
      <f-col
        class="required"
        md="12"
      >
        <f-input
          v-model="deployment.name"
          :label="$t('labels.repositoryName')"
          rules="required"
        />
      </f-col>

      <f-col>
        <b-form-checkbox
          id="hasDeploymentUrl"
          v-model="deployment.hasDeploymentUrl"
          class="mt-4 mb-4 text-bold"
          name="hasDeploymentUrl"
        >
          {{ $t('labels.deploymentUrls') }}
        </b-form-checkbox>
      </f-col>
    </f-row>
    <f-row
      v-if="deployment.hasDeploymentUrl"
      class="mt-5"
    >
      <f-col md="12">
        <f-input
          v-model="deployment.stagingUrl"
          :label="$t('labels.stagingUrl')"
        />
      </f-col>
      <f-col md="12">
        <f-input
          v-model="deployment.productionUrl"
          :label="$t('labels.productionUrl')"
        />
      </f-col>
    </f-row>
  </div>
</template>

<script>
export default {
  props: {
    deployment: {
      type: Object
    }
  }
}
</script>
