<template>
  <div>
    <f-select
      v-model="deployment.deploymentType"
      :label="$t('labels.deployType')"
      :options="deploymentTypes"
      :placeholder="$t('labels.selectDeployType')"
      rules="required"
      value-type="string"
      @change="handleForceChange"
    />
    <f-switch
      v-model="deployment.isForce"
      :default-value="deployment.isForce"
      :false-label="'Force Deploy'"
      :true-label="'Force Deploy'"
    />
    <f-modal
      ref="modalProductionMessage"
      cancel-disabled
      header-bg-variant="warning"
      header-text="center"
      size="sm"
      submit-disabled
      :title="$t('labels.warning')"
    >
      <template
        #footer
      >
        <f-button
          block
          :label="$t('labels.ok')"
          variant="warning"
          @click="onClickCloseDeployMessageModal"
        />
      </template>
      <div class="d-block text-center">
        <h4 class="text-capitalize">
          Production Seçildi
        </h4>
      </div>
    </f-modal>
  </div>
</template>

<script>
export default {
  props: {
    deployment: {
      type: Object
    }
  },
  data() {
    return {
      deploymentTypes: [
        {
          id: 'staging',
          name: this.$i18n.t('labels.staging')
        },
        {
          id: 'master',
          name: this.$i18n.t('labels.production')
        }
      ]
    }
  },
  methods: {
    handleForceChange(deploymentType) {
      if (deploymentType === 'master') {
        this.$refs.modalProductionMessage.show()
      }
    },
    onClickCloseDeployMessageModal() {
      this.$refs.modalProductionMessage.hide()
    }
  }
}
</script>
