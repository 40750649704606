<template>
  <f-page :title="$t('labels.deployments')">
    <f-card>
      <f-datatable
        ref="deploymentTable"
        :actions="deploymentActions"
        :columns="deploymentColumns"
        :data-url="'/projects/repositories'"
        :features="{
          create: isCreateDeploymentEnabled,
          update: true,
          delete: true
        }"
        :query-params="search"
        @action="onClickOpenDeploy"
        @create="onClickCreateDeployment"
        @delete="onClickDeleteDeployment"
        @update="onClickUpdateDeployment"
      />
    </f-card>
    <f-modal
      ref="modalDeploymentEdit"
      size="lg"
      :title="$t('labels.repo')"
      @submit="saveDeployment"
    >
      <template
        v-if="selectedDeployment.id"
        slot="header-right"
      >
        <f-user-edit-info
          v-if="selectedDeployment"
          :data="selectedDeployment"
        />
      </template>
      <modal-deployment-edit :deployment="selectedDeployment" />
    </f-modal>
    <f-modal
      ref="modalDeployment"
      cancel-disabled
      size="lg"
      submit-disabled
      :title="selectedDeployment.name"
    >
      <template
        v-if="selectedDeployment.id"
        slot="header-right"
      >
        <f-user-edit-info
          v-if="selectedDeployment"
          :data="selectedDeployment"
        />
      </template>
      <template slot="footer">
        <f-button
          :disabled="
            !selectedDeployment.deploymentType && !selectedDeployment.isForce
          "
          icon="rocket"
          :label="$t('labels.deploy')"
          variant="primary"
          @click="onClickDeploy"
        />
      </template>

      <ModalDeployment :deployment="selectedDeployment" />
    </f-modal>
    <f-modal
      ref="deploymentStatus"
      cancel-disabled
      hide-footer
      size="md"
      submit-disabled
      :title="deploymentHeader()"
    >
      <div
        aria-atomic="true"
        class="alert alert-light"
        role="alert"
        show="true"
        :style="{
          'background-color':
            deploymentResponse.status == 201 || deploymentResponse.message
              ? 'palegreen'
              : 'pink'
        }"
        variant="light"
      >
        {{ deploymentMessage() }}
      </div>
      <br>

      <div class="d-flex justify-content-end">
        <f-button
          :label="$t('labels.ok')"
          variant="light"
          @click="
            () => {
              $refs.deploymentStatus.hide()
            }
          "
        />
      </div>
    </f-modal>
  </f-page>
</template>

<script>
import { deploymentColumns } from '@/data/columns'
import { deploymentStatus } from '@/data/enums'
import ModalDeploymentEdit from './ModalDeploymentEdit'
import ModalDeployment from './ModalDeployment'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalDeploymentEdit,
    ModalDeployment
  },
  data() {
    return {
      search: {},
      selectedDeployment: {},
      deploymentStatus,
      deploymentResponse: {},
      deploymentColumns,
      deploymentActions: [
        {
          name: 'deploy',
          icon: 'rocket',
          title: this.$t('labels.deploy')
        }
      ],
      fakeDeployment: [
        {
          repositoryName: 'fake',
          stagingUrl: 'fake',
          productionUrl: 'fake'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['user', 'selectedProjectId']),
    isCreateDeploymentEnabled() {
      return !(this.selectedProjectId?.length > 0) && this.selectedProjectId
    }
  },
  created() {
    this.search.projectId = this.selectedProjectId
  },
  methods: {
    async saveDeployment() {
      if (this.selectedDeployment.id) {
        await this.$http.put(
          `/projects/repositories/${this.selectedDeployment.id}`,
          this.selectedDeployment
        )
      } else {
        await this.$http.post('/projects/repositories', this.selectedDeployment)
      }

      this.$refs.deploymentTable.refresh()
      this.$refs.modalDeploymentEdit.hide()
    },
    onClickCreateDeployment() {
      this.selectedDeployment = {
        createdById: this.user.id,
        projectId: this.selectedProjectId,
        hasDeploymentUrl: false
      }
      this.$refs.modalDeploymentEdit.show()
    },
    async onClickUpdateDeployment(row) {
      this.selectedDeployment = await this.$http.get(
        `/projects/repositories/${row.id}`
      )
      this.$refs.modalDeploymentEdit.show()
    },
    async onClickDeleteDeployment(row) {
      await this.$http.delete(`/projects/repositories/${row.id}`)
      this.$refs.deploymentTable.refresh()
    },
    async onClickOpenDeploy(row) {
      this.selectedDeployment = await this.$http.get(
        `/projects/repositories/${row.id}`
      )
      this.selectedDeployment.isForce = false
      this.selectedDeployment.deploymentType = 'staging'
      this.$refs.modalDeployment.show()
    },
    async onClickDeploy() {
      const { status, message } = await this.$http.post(
        `/projects/repositories/${this.selectedDeployment.id}/deploy`,
        this.selectedDeployment
      )

      this.deploymentResponse = {
        status,
        message: message ? this.$t('messages.startedMerging') : message
      }

      this.$refs.modalDeployment.hide()
      this.$refs.deploymentStatus.show()
    },
    deploymentHeader() {
      if (this.deploymentResponse.message) {
        return this.selectedDeployment.deploymentType === 'staging'
          ? 'Staging Deploy ' + '[Force]'
          : 'Production Deploy ' + '[Force]'
      }

      return this.selectedDeployment.deploymentType === 'staging'
        ? 'Staging Deploy'
        : 'Production Deploy'
    },
    deploymentMessage() {
      if (this.deploymentResponse.message) {
        return this.deploymentResponse.message
      }

      return deploymentStatus[this.deploymentResponse.status]
        ? this.$t(
          `messages.${deploymentStatus[this.deploymentResponse.status]}`
        )
        : this.$t('messages.deploymentStatusUnknown') +
            '\n' +
            'Status Code: ' +
            this.deploymentResponse.status
    }
  }
}
</script>
